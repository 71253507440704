(function () {
  'use strict';

  /* @ngdoc object
   * @name home.phases
   * @description
   *
   */
  angular
    .module('neo.home.phases', [
      'ui.router',
      'neo.home.phases.newPhase',
      'neo.home.phases.inscription',
      'neo.home.phases.phase'
    ]);
}());
